import Rails from '@rails/ujs';
import "./application.scss"
import Swal from 'sweetalert2';
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

window.Swal = Swal;
Turbolinks.start();
Rails.start();
ActiveStorage.start();

document.addEventListener("DOMContentLoaded", function() {
    window.toggleSidebar = function() {
        const sidebar = document.getElementById("sidebarContainer");
        sidebar.classList.toggle("toggled");
    };
});

document.addEventListener('turbolinks:before-render', () => {
  $( ".loadData").unbind( "click" );
});

document.addEventListener("turbolinks:before-cache", function() {
  const flash_message_element = document.querySelector(".custom-alert")
  if (flash_message_element) {
    flash_message_element.remove()
  }
})

$(document).on('ready turbolinks:load', function () {

  $(document).off("click", ".btnExtendTrial").on("click", ".btnExtendTrial", function(){
    Swal.fire({
      title: 'Are you sure you want to extend this trial?',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('Trial Extended', '', 'success')
      }
    });
  });

  $(document).off("click", ".cancelTrialButton").on("click", ".cancelTrialButton", function(){
    var obj = $(this);
    var parent_container = obj.parents().eq(7);
    var parent = obj.parents().eq(6);
    var totalEntries = parent_container.length;
    Swal.fire({
      title: 'Are you sure you want to cancel this trial?',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('Trial Cancelled', '', 'success');
        parent.slideUp(300,function(){
          parent.remove();
        });
      }
    });
  });


  $(document).off("click", ".loadData").on("click", ".loadData", function(){
    var obj = $(this);
    var url = obj.attr('data-link');
    var target = obj.attr('data-bs-target');

    $.ajax({
      type: 'get',
      url: url,
      beforeSend: function(){
        $(target + "  .modal-content").html(`<div class="modal-header"><h5 class="modal-title" id="applicationDetailsModalLabel">&nbsp;</h5><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body"><div class="d-flex align-items-center justify-content-center" bis_skin_checked="1" style="height: 200px;">  <div class="col d-flex justify-content-center align-items-center" bis_skin_checked="1">      <span>Loading...</span>  </div></div></div><div class="modal-footer"></div>`);
      },
      success: function(data){
        $(target + "  .modal-content").html(data);
      }
    });

  });

  $(".modal").on('show.bs.modal', function(){
    setTimeout(function(){
      if($(".modal-backdrop").length > 1){
        $(".modal-backdrop:last-child").css("z-index",1061);
      }
      if($(".modal.show").length > 1){
        let x = 0;
        $(".modal.show").each(function(){
          x++;
          if(x >= $(".modal.show").length){
            $(this).css("z-index",1062);
          }
        });
      }
    },100);
  });

  $(document).off("submit", ".form-trial-confirm").on("submit", ".button_to", function(e){
    var obj = $(this);
    var input = obj.children("input[type=submit]");

    if(input.attr("data-verified") == "false"){
      e.preventDefault();
      Swal.fire({
        type: 'question',
        title: 'Are you sure you want to extend this trial?', 
        showCancelButton: true,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          input.attr("data-verified","true");
          obj.children(".form-trial-confirm").toggleClass("hidden");
          obj.children(".btnTrialLoaderDummy").first().toggleClass("hidden");
          obj.submit();
        }else{
          input.attr("data-verified","false");
          return false;
        }
      });
    }else{
      return true;
    }
  });

  if($("body.email_domain")){
    window.resetEmailModal = () => {
        $(".identifier-name").val('');
        $(".email-domain").val('');
        $(".modal-body").removeAttr("id");
    };

    window.resetIPAddressModal = () => {
      $(".ipaddress").val('');
      $(".iprange").val('');
      $(".modal-body").removeAttr("id");
    }

    //Reset email and ip modals when closed or when the modal is hidden
    $(document).on('hidden.bs.modal', '.emailDomainsModal', function () {
      resetEmailModal();
    });

    $(document).on('hidden.bs.modal', '.ipAddressModal', function () {
      resetIPAddressModal();
    });

    $(document).off("click", ".emailDomainsClose").on("click", ".emailDomainsClose", function () {
      resetEmailModal();
    });

    $(document).off("click", ".ipAddressModalClose").on("click", ".ipAddressModalClose", function () {
      resetIPAddressModal();
    });


    $(document.body).off("click", ".btnAddEmailDomain").on("click", ".btnAddEmailDomain", function(){
      let button = $(this);
      var app_id = $('.modal-body').attr('id');
      var identifier = $('.identifier-name').val();
      var domain = $('.email-domain').val();
      var status = "blocked"

      var emailDomainInput = $(".email-domain")[0].reportValidity();
      var identifierInput = $(".identifier-name")[0].reportValidity();

      if(identifierInput && emailDomainInput){
        if ($("#emailDomainsModalLabel").text()=="Edit Email Domain"){
          let email_domain = JSON.stringify({
              name: identifier,
              domain: domain
          })
          var formData = new FormData();
          formData.append('email_domain', email_domain);
        
          $.ajax({
            url: '/setup/rules/email_domain/'+app_id,
            type: 'PUT',
            enctype: 'multipart/form-data',
            contentType: false,
            processData: false,
            cache: false,
            data: formData,
            beforeSend: function(){
              button.toggleClass("hidden");
              button.parent().children(".btnEmailDummyLoader").toggleClass("hidden");
            },
            success: function(){
              location.reload();
            }
          });
        }
        else{
          let email_domain = JSON.stringify({
                name: identifier,
                domain: domain,
                status: status
              })
          var formData = new FormData();
          formData.append('email_domain', email_domain);
          
          $.ajax({
                url: '/setup/rules/email_domain',
                type: 'POST',
                enctype: 'multipart/form-data',
                contentType: false,
                processData: false,
                cache: false,
                data: formData,
                beforeSend: function(){
                  button.toggleClass("hidden");
                  button.parent().children(".btnEmailDummyLoader").toggleClass("hidden");
                },
                success: function(){
                  location.reload();
                }
              });

        }
      }

    });

    $(document.body).off("click", ".btnAddIpaddress").on("click", ".btnAddIpaddress", function(){
      var button = $(this);
      var app_id = $('.modal-body').attr('id');
      var identifier = $('.ipaddress').val();
      var ipaddress = $('.iprange').val();
      var status = "blocked"

      var ipRangeInput = $(".iprange")[0].reportValidity();
      var ipAddressInput = $(".ipaddress")[0].reportValidity();

      if(ipAddressInput && ipRangeInput){
        if ($("#ipAddressModalLabel").text()=="Edit IP Address"){
          let ip_address = JSON.stringify({
              name: identifier,
              ip_range: ipaddress
          })
          var formData = new FormData();
          formData.append('ipaddress', ip_address);
        
          $.ajax({
            url: '/setup/rules/ip_address/'+app_id,
            type: 'PUT',
            enctype: 'multipart/form-data',
            contentType: false,
            processData: false,
            cache: false,
            data: formData,
            beforeSend: function(){
              button.toggleClass("hidden");
              button.parent().children(".btnAddIpaddressDummyLoader").toggleClass("hidden");
            },
            success: function(){
              location.reload();
            }
          });
        }
        else{

        let ip_address = JSON.stringify({
              name: identifier,
              ip_range: ipaddress,
              status: status
            })
        var formData = new FormData();
        formData.append('ipaddress', ip_address);
        $.ajax({
              url: '/setup/rules/ip_address',
              type: 'POST',
              enctype: 'multipart/form-data',
              contentType: false,
              processData: false,
              cache: false,
              data: formData,
              beforeSend: function(){
                button.toggleClass("hidden");
                button.parent().children(".btnAddIpaddressDummyLoader").toggleClass("hidden");
              },
              success: function(){
                location.reload();
              }
            });
        }
      }

    });

    $(document).off("click", ".editEmailDomain").on("click", ".editEmailDomain", function(){
      let button = $(this);
      $("#emailDomainsModalLabel").text("Edit Email Domain")
      var app_id = $(this).attr("data-email-id");
      var identifier=$(".emailDomainIdentifier_"+app_id).attr("data-identifier");
      var domain=$(".emailDomain_"+app_id).attr("data-domain");
      $(".modal-body").attr("id",app_id);
      $(".identifier-name").val(identifier);
      $(".email-domain").val(domain);
    });

    $(document).off("click", ".editIpAddress").on("click", ".editIpAddress", function(){
      let button = $(this);
      $("#ipAddressModalLabel").text("Edit IP Address");
      var app_id = $(this).attr("data-ip-id");
      var ipaddress=$(".ipAddressIdentifier_"+app_id).attr("data-identifier");
      var range=$(".ipAddressRange_"+app_id).attr("data-range");
      $(".modal-body").attr("id",app_id);
      $(".ipaddress").val(ipaddress);
      $(".iprange").val(range);
    });
  }
  
  if($("body.staff")){
    $.ajax({
      url: "/setup/staff/countries",
      beforeSend: function(){

      },
      success: function(data){
        data.sort((a, b) => a.name.localeCompare(b.name));
        var html = "";
        var country_list_html = "";
        var current_letter = "";
        $.each(data, function(index, value){
          if(current_letter != value.name.charAt(0).toUpperCase()){
            current_letter = value.name.charAt(0).toUpperCase();
            html += "<li class='form-check p-2 rounded-3 list-head'>"+current_letter.toUpperCase()+"</li>";
            country_list_html += "<li class='form-check p-2 rounded-3 list-head'>"+current_letter.toUpperCase()+"</li>";
          }
          html += '<li class="form-check">'+
                  '    <input class="form-check-input countryCheckbox" type="checkbox" id="c'+value.id+'" data-value="'+value.name+'" data-id="'+ value.id +'" value="'+value.id+'" id="flexCheckDefault">'+
                  '    <label class="form-check-label" for="c'+value.id+'">'+
                        value.name+
                  '    </label>'+
                  '  </li>';
          country_list_html += '<li class="form-check">'+
                              '    <input class="form-check-input e_countryCheckbox" type="checkbox" id="e_c'+value.id+'" data-value="'+value.name+'" data-id="'+ value.id +'" value="'+value.id+'" id="flexCheckDefault">'+
                              '    <label class="form-check-label" for="e_c'+value.id+'">'+
                                    value.name+
                              '    </label>'+
                              '  </li>';
        });
        $(".country-listing").html(html);
        $(".geo_group_country_list").html(country_list_html);
      }
    });

    $(document).off("click", ".btnSelectCountry").on("click", ".btnSelectCountry", function(){
      var html = "";
      var countryIds = []
      $(".countryCheckbox:checked").each(function(){
        html += `<li>${$(this).attr("data-value")}</li>`;
        countryIds.push($(this).data("id"));
      });
      if (html) {
        html = '<ul>' + html + '</ul';
        $(".activatedCountry").html(html);
      } else {
        $(".activatedCountry").html('<small>No country mapped yet.</small>');
      }
      $("#staff_countries").val(JSON.stringify(countryIds));
      $("#staffCountry").modal("hide");
    });

    $(document).off("click", ".btnShowCountryListing").on("click", ".btnShowCountryListing", function(){
      $("#staffCountry").modal("show");
    });

    $(document).off("click", "#addNewStaffBtn").on("click", "#addNewStaffBtn", function(){
      $("#deleteStaffBtn").hide();
      $("#staff_name").val("");
      $("#staff_role").val("");
      $("#staff_tel").val("");
      $("#staff_email").val("");
      $("#staff_countries").val(JSON.stringify([]));
      $("#staffModalLabel").html("Add New Staff");
      $(".activatedCountry").html('<small>No country mapped yet.</small>');
      $(".countryCheckbox").each(function(){
        $(this).prop("checked", false);
      });
      $("#saveStaffBtn").data("type", "add");
    });

    $(document).off("click", ".btn-edit-staff").on("click", ".btn-edit-staff", function(){
      $("#deleteStaffBtn").show();
      $("#staff_id").val($(this).data("id"));
      $("#staff_name").val($(this).data("name"));
      $("#staff_role").val($(this).data("roleId"));
      $("#staff_tel").val($(this).data("tel"));
      $("#staff_email").val($(this).data("email"));
      $("#staffModalLabel").html($(this).data("name") + '<span>' + $(this).data("email") + '</span>');
      $("#staff_email").attr("readonly","readonly");
      var html = "";
      var countryIds = []

      $(this).data("countries").forEach(function(country) {
        html += `<li>${country.name}</li>`;
        countryIds.push(country.id)
      })
      if (html) {
        html = '<ul>' + html + '</ul';
        $(".activatedCountry").html(html);
      } else {
        $(".activatedCountry").html('<small>No country mapped yet.</small>');
      }
      $("#staff_countries").val(JSON.stringify(countryIds));
      $(".countryCheckbox").each(function(){
        if (countryIds.includes($(this).data("id"))) {
          $(this).prop("checked", true);
        } else {
          $(this).prop("checked", false);
        }
      });
      $("#saveStaffBtn").data("type", "edit");
    });

    $(document).off("click", "#saveStaffBtn").on("click", "#saveStaffBtn", function() {
      var requestURL, requestMethod, requestData;
      var obj = $(this);
      var staff_id
      //check if all fields are valid
      var staffChecker = $("#staff_email")[0].reportValidity();
      var telChecker = $("#staff_tel")[0].reportValidity();
      var roleChecker = $("#staff_role")[0].reportValidity();
      var nameChecker = $("#staff_name")[0].reportValidity();
      var countryChecker = $("#staff_countries").val().length > 2 ? true : false;

      if(staffChecker && telChecker && roleChecker && nameChecker){
        if(!countryChecker){
          Swal.fire({
            icon: "error",
            title: "Country Mapping Not Found",
            html: "Please assign this staff to at least 1 country"
          })
        }
      }

      if(staffChecker && telChecker && roleChecker && nameChecker && countryChecker){
        if (obj.data("type") == "edit") {
          staff_id = $("#staff_id").val()
          requestURL = "/setup/staff/"+staff_id
          requestMethod = "PUT"
        } else {
          requestURL = "/setup/staff"
          requestMethod = "POST"
        }
        requestData = {
          name: $("#staff_name").val(),
          email: $("#staff_email").val(),
          role_id: $("#staff_role").val(),
          telephone: $("#staff_tel").val(),
          countries: $("#staff_countries").val()
        }

        $.ajax({
          url: requestURL,
          method: requestMethod,
          data: requestData,
          beforeSend: function(){
            obj.toggleClass("hidden");
            obj.parent().children(".btnSaveButtonDummy").toggleClass("hidden");
          },
          success: function(data) {
            obj.toggleClass("hidden");
            obj.parent().children(".btnSaveButtonDummy").toggleClass("hidden");
            $('.staffDetailsModal').modal('hide')
            let countries_list = [];
            if (data["countries"].length > 1) {
              countries_list = data["countries"][0].name + ", +" + (data["countries"].length - 1) + " more"
            }else {
              countries_list = data["countries"][0].name
            }
            let country_listing = JSON.stringify(data['countries']);
            let html  = '<div class="col-md-12 col-xl-12" id="staff-'+data['staff'].id+'">'+
                      '  <div class="flex-fill w-100 card staff">'+
                      '    <div class="d-flex card-body resdetails">'+
                      '      <div class="col-3 restaurant-details btn-edit-staff" data-bs-toggle="modal" data-bs-target="#staffModal"'+
                      '      data-id="'+data['staff'].id+'" data-name="'+data['staff'].name+'" data-email="'+data['staff'].email+'" data-tel="'+data['staff'].telephone+'" data-role-id="'+data['staff'].role_id+'" data-countriess=\''+country_listing+'\' >'+
                      '       <strong>'+data['staff'].name+'</strong>'+
                      '        <small>'+data['staff'].email+'</small>'+
                      '      </div>'+
                      '      <div class="col">Active</div>'+
                      '      <div class="col">'+data["role_name"]+'</div>'+
                      '     <div class="col">'+data['staff'].telephone+'</div>'+
                      '      <div class="col">'+countries_list+'</div>'+
                      '      <div class="col">'+
                      '        <button data-bs-toggle="modal" data-bs-target="#staffModal" class="btn btn-primary btn-edit-staff" data-id="'+data['staff'].id+'" data-name="'+data['staff'].name+'" data-email="'+data['staff'].email+'" data-tel="'+data['staff'].telephone+'" data-role-id="'+data['staff'].role_id+'" data-countries=\''+country_listing+'\'>Edit</button>'+
                      '      </div>'+
                      '   </div>'+
                      '  </div>'+
                      '</div>'

            if (obj.data("type") == "edit") {
              $('#staff-'+data['staff'].id+'').html(html)
            } else{
              $('.staffList').append(html);
            }
          },
          error: function(data) {
            //alert("Email already taken"); // TODO: Replace the line the valid error response
            $("small.emailerror").fadeIn();
            obj.toggleClass("hidden");
            obj.parent().children(".btnSaveButtonDummy").toggleClass("hidden");
          }
        });
      }
    });

    $(document).off("click", "#deleteStaffBtn").on("click", "#deleteStaffBtn", function() {
      var obj = $(this);
      $.ajax({
        url: "/setup/staff/"+$("#staff_id").val(),
        method: 'DELETE',
        data: '',
        beforeSend: function(){
          obj.toggleClass("disabled").html($(".staff-button-loader-container").html() +' Deleting...');
        },
        success: function(data) {
          obj.toggleClass("disabled").html('Delete');;
          $('.staffDetailsModal').modal('hide')
          $('#staff-'+data['id']+'').remove()
        }
      });
    });
    
    setTimeout(function(){
      $(".btnSaveButton").each(function(){
        var obj = $(this);
        var parent = obj.parent();
        if(parent.children("btnSaveButtonDummy").length <= 0){
          parent.append('<button type="button" class="btn btn-primary btnSaveButtonDummy disabled hidden" disabled="disable">'+ $(".staff-button-loader-container").html() +' Saving...</button>');
        }
      });
    },1000);
  }

  $(document).on('hidden.bs.modal', '#staffModal', function () {
    $("#staff_email").removeAttr("readonly");
    $(".emailerror").hide();
  });

  if($("body.countries")){
    window.countTheCountries = function(){
      setTimeout(function(){
        var totalActive = $(".list-group.active").children().length;
        var totalInactive = $(".list-group.inactive").children().length;
        var totalEmbargo = $(".list-group.embargo").children().length;
        $(".total-active").html(totalActive);
        $(".total-inactive").html(totalInactive);
        $(".total-embargo").html(totalEmbargo);
      },800);
    }

    function sort_li(a, b) {
      return ($(b).data('position')) < ($(a).data('position')) ? 1 : -1;
    }

    $(document).off('click', "button.move").on('click', "button.move", function(){
      var obj = $(this);
      var destination = obj.attr("data-to");
      var country = obj.attr("data-countryname");
      var country_id = obj.attr("data-country-id");
      var code = obj.attr("data-countrycode");
      var parent = obj.parent();
      var from = obj.attr("data-from");

      if(destination == "disable"){
          var status = "inactive"

          let html = '<li class="list-group-item d-flex align-items-center justify-content-start addToInactiveFrom'+from+'" data-name="'+country+'">'+
                           '<span class="col">'+country+'</span>'+
                           '<button class="btn btn-primary ms-2 move" data-to="enable" data-from="inactive" data-country-id="'+country_id+'" data-countryname="'+country+'" data-countrycode="'+code+'">Enable</button>'+
                           '<button class="btn btn-danger  ms-2 move" data-to="embargo" data-from="inactive" data-country-id="'+country_id+'" data-countryname="'+country+'" data-countrycode="'+code+'">Embargo</button>'+
                           '</li>';
          $(".list-group.inactive").append(html);

          sortList("inactive-list");
          $(".addToInactiveFrom"+from)[0].scrollIntoView();


      }else if(destination == "embargo"){
          var status = "embargo"
          
          let html = '<li class="list-group-item d-flex align-items-center justify-content-start  addToEmbargoFrom'+from+'" data-name="'+country+'">'+
                           '<span class="col">'+country+'</span>'+
                           '<button class="btn btn-primary ms-2 move" data-to="enable" data-from="embargo" data-country-id="'+country_id+'" data-countryname="'+country+'" data-countrycode="'+code+'">Enable</button>'+
                           '</li>';
          $(".list-group.embargo").append(html);

          sortList("embargo-list");
          $(".addToEmbargoFrom"+from)[0].scrollIntoView();

      }else{
          var status = "active"
          
          let html = '<li class="list-group-item d-flex align-items-center justify-content-start  addToActiveFrom'+from+'" data-name="'+country+'">'+
                           '<span class="col">'+country+'</span>'+
                           '<button class="btn btn-primary ms-2 move" data-to="disable" data-from="active" data-country-id="'+country_id+'" data-countryname="'+country+'" data-countrycode="'+code+'">Disable</button>'+
                           '<button class="btn btn-danger  ms-2 move" data-to="embargo" data-from="active" data-country-id="'+country_id+'" data-countryname="'+country+'" data-countrycode="'+code+'">Embargo</button>'+
                           '</li>';
          $(".list-group.active").append(html);
          
          sortList("active-list");
          if($(".addToActiveFrom"+from).prev().length > 0 ){
            $(".addToActiveFrom"+from).prev()[0].scrollIntoView();
          }else{
            $(".list-group.active").parent().scrollTop(0);
          }
      }

      $.ajax({
          url: "/setup/rules/countries/"+country_id+"?status="+status,
          type:'Put'
      
      });

      parent.addClass("removeFrom"+destination+"From"+from);
      
      setTimeout(function(){
        parent.remove();
        $(".addToInactiveFromactive").removeClass("addToInactiveFromactive");
        $(".addToEmbargoFrominactive").removeClass("addToEmbargoFrominactive");
        $(".addToEmbargoFromactive").removeClass("addToEmbargoFromactive");
        $(".removeFromembargoFromactive").removeClass("removeFromembargoFromactive");
        $(".removeFromembargoFrominactive").removeClass("removeFromembargoFrominactive");
        $(".removeFromdisableFromactive").removeClass("removeFromdisableFromactive");
        $(".addToActiveFromembargo").removeClass("addToActiveFromembargo");
        $(".removeFromenableFromembargo").removeClass("removeFromenableFromembargo");
        $(".removeFromenableFrominactive").removeClass("removeFromenableFrominactive");
        $(".addToActiveFrominactive").removeClass("addToActiveFrominactive");
      },800);

      countTheCountries();

    });

    let sortList = (ul_id) => {
      var list, i, switching, b, shouldSwitch;
      list = document.getElementById(ul_id);
      switching = true;
      while (switching) {
        switching = false;
        b = list.getElementsByTagName("LI");
        for (i = 0; i < (b.length-1); i++) {
          shouldSwitch = false;
          if (b[i].getAttribute("data-name").toLowerCase() > b[i+1].getAttribute("data-name").toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
        if (shouldSwitch) {
          b[i].parentNode.insertBefore(b[i + 1], b[i]);
          switching = true;       
        }
      }
    }

    //initialize the country counts
    countTheCountries();

  }


});
